<template>
  <Modal
    :modalTitle="modalTitle"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveLink"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div ref="baseForm">
        <template v-if="sourceType">
          <div class="mb-5">
            <div class="has-text-weight-bold mb-1">{{$t('Components.Article.ArticleDetail.ModalAddEditArticleSource.Label_SourceSelection')}}:</div>
            <Toggle
              v-model="sourceType"
              :radioOptions="linkTypes"
              objectTextField="Name"
              objectValueField="Type"
              inputCssClass=""
            />
          </div>

          <template v-if="isLink">
            <ui-base-input-field
              :label="$t('Components.Article.ArticleDetail.ModalAddEditArticleSource.Text_Title')"
              v-model="newLink.Text"
              :hasFloatingLabel="true"
              inputCssClass=""
              maxLength="100"
              class="mb-4"
              :error="textErrors"
              @input="$v.newLink.Text.$touch()"
              @blur="$v.newLink.Text.$touch()"
            />
            <ui-base-template :label="$t('Components.Article.ArticleDetail.ModalAddEditArticleSource.Text_LinkUrl')">
              <template #fields>
                <div class="field has-addons">
                  <p class="control">
                    <ui-select
                      v-model="protocol"
                      :options="protocolOptions"
                      inputCssClass=""
                    />
                  </p>
                  <p class="control is-expanded">
                    <ui-base-input-field
                      v-model="newLink.Link"
                      :hasFloatingLabel="true"
                      inputCssClass=""
                      :error="linkErrors"
                      @input="() => { splitUrl(); $v.newLink.Link.$touch() }"
                      @blur="$v.newLink.Link.$touch()"
                    />
                  </p>
                </div>
              </template>
            </ui-base-template>
          </template>

          <div
            v-if="newLink && !isLink"
            class="columns is-multiline"
          >
            <div class="column">
              <ui-base-input-field
                v-model="newLink.Text"
                :labelForInput="false"
                :label="$t('Components.Article.ArticleDetail.ModalAddEditArticleSource.Text_Title')"
                inputCssClass=""
                maxLength="100"
                class="mb-4"
                :error="textErrors"
                @input="$v.newLink.Text.$touch()"
                @blur="$v.newLink.Text.$touch()"
              />
              <ui-base-input-field
                v-model="newLink.Link"
                :labelForInput="false"
                :label="$t('Components.Article.ArticleDetail.ModalAddEditArticleSource.Text_EmbedUrl')"
                inputCssClass=""
                :error="linkErrors"
                @input="$v.newLink.Link.$touch()"
                @blur="$v.newLink.Link.$touch()"
              />
            </div>
            <div class="column">
              <component
                v-bind:is="getComponentType(newLink)"
                :src="newLink.Link"
              ></component>
            </div>
          </div>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script>
import articleProvider from '@/providers/article'
import Modal from '@/components/UI/Components/Modal.vue'
import Toggle from '@/components/UI/Form/Toggle.vue'
import YoutubeEmbed from '@/components/UI/Components/YoutubeEmbed.vue'
import VimeoEmbed from '@/components/UI/Components/VimeoEmbed.vue'
import { cleanSource } from '@/utils/objectHelper'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ModalAddEditArticleSource',

  components: {
    Modal,
    Toggle,
    YoutubeEmbed,
    VimeoEmbed
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
    articleId: {
      type: Number,
      required: true
    },
    source: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newLink: null,
      linkTypes: [],
      sourceType: this.source?.LinkType || 'ExternalUrl',
      protocol: 'https://',
      protocolOptions: ['https://', 'http://'],
      modalTitle: this.source ?
        // Edit existing source
        this.$t('Components.Article.ArticleDetail.ModalAddEditArticleSource.Title_EditSource')
        :
        // add new source
        this.$t('Components.Article.ArticleDetail.ModalAddEditArticleSource.Title_AddSource')
    }
  },

  validations() {
    let output = {
      Text: { required },
      Link: { required },
    }
    return {
      newLink: output
    }
  },

  computed: {
    isLink() {
      return this.sourceType === 'ExternalUrl' || this.sourceType === 'InternalUrl'
    },

    linkErrors() {
      const errors = []
      if (!this.$v.newLink.Link.$dirty) return errors
      !this.$v.newLink.Link.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      return errors
    },

    textErrors() {
      const errors = []
      if (!this.$v.newLink.Text.$dirty) return errors
      !this.$v.newLink.Text.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      return errors
    },
  },

  watch: {
    sourceType: {
      immediate: true,
      handler: function(val) {
        if (val === this.source?.LinkType) {
          this.newLink = cleanSource(this.source)
          this.splitUrl()
          return
        }

        this.newLink = {
          Id: this.source?.Id || 0,
          ArticleId: this.articleId,
          Text: '',
          LinkType: this.sourceType,
          Link: '',
          IsExternal: true,
        }

        this.protocol = 'https://'
      }
    },
  },

  created() {
    this.linkTypes.push({
      Type: 'ExternalUrl',
      Name: this.$t(
        'Components.Article.ArticleDetail.ModalAddEditArticleSource.Text_TypeExternalLink'
      ),
    })
    this.linkTypes.push({ Id: 2, Type: 'YoutubeVideo', Name: 'Youtube' })
    this.linkTypes.push({ Id: 3, Type: 'VimeoVideo', Name: 'Vimeo' })
  },

  mounted() {
    if (this.source) {
      this.$v.$touch()
    }
  },

  methods: {
    getComponentType(videoSource) {
      let component = ''

      switch (videoSource.LinkType) {
        case 'YoutubeVideo':
          component = YoutubeEmbed
          break;
        case 'VimeoVideo':
          component = VimeoEmbed
          break;
      }
      return component
    },

    splitUrl() {
      if (this.sourceType !== 'ExternalUrl'
        || !this.newLink?.Link
        || (this.newLink.Link.indexOf('http://') === -1 && this.newLink.Link.indexOf('https://') === -1)
      ) { return }
      if (
        (this.newLink.Link.indexOf('http://') === -1 &&
          this.newLink.Link.indexOf('https://') === -1) ||
        this.newLink.Link.indexOf('https://') !== -1
      ) {
        this.protocol = 'https://'
      } else {
        this.protocol = 'http://'
      }

      this.newLink.Link = this.newLink.Link.trim().replace(/^https?\:\/\//i, '')
    },

    setLinkType(type) {
      this.newLink.LinkType = type
      this.newLink.IsExternal = type === 'ExternalUrl'
    },

    async saveLink() {
      if (this.isSaving) { return }

      // Validate form
      await this.$v.$touch()

      // Stop processing when FORM data is invalid
      if (this.$v.$invalid) { return }

      this.isSaving = true

      if (this.newLink.LinkType === 'ExternalUrl') {
        this.newLink.Link = this.protocol + this.newLink.Link
      }

      try {
        let response = this.newLink.Id === 0 ?
          // New article link
          await articleProvider.methods.createArticleLink(this.newLink)
          :
          // update article link
          await articleProvider.methods.updateArticleLink(this.newLink)

        this.$emit('updated', response.data)

        this.isSavingSuccess = true
        let t = setTimeout(() => {
          this.onClickCancel()
          clearTimeout(t)
        }, 1500)
      }
      catch {
        this.isSavingError = true
        this.isSaving = false
      }
    },
  },
}
</script>
